<template>
  <div class="app-container property-query-component">
    <div class="filter-line">
      <div class="single-line">
        <span class="query-label"> 查询方式： </span>
        <el-select
          v-model="listQuery.type"
          placeholder="请选择查询类型"
          class="query-type-select single-one"
        >
          <el-option
            v-for="(item, index) in queryTypeList"
            :value="item.value"
            :label="item.label"
            :key="index"
          ></el-option>
        </el-select>
        <span class="query-label"> 上报时间： </span>
        <el-date-picker
          style="width: 200px"
          v-model="listQuery.startTime"
          value-format="timestamp"
          type="datetime"
          placeholder="选择开始时间"
        >
        </el-date-picker>
        <span class="date-range-span"> - </span>
        <el-date-picker
          style="width: 200px; margin-right: 20px"
          value-format="timestamp"
          v-model="listQuery.endTime"
          type="datetime"
          placeholder="选择结束时间"
        >
        </el-date-picker>
        <el-button type="primary" @click="queryHandler(true)">查询</el-button>
        <el-button @click="exportHandler">导出</el-button>
        <el-button
          type="primary"
          icon="el-icon-upload2"
          size="mini"
          plain
          @click="uploadModel = true"
          >{{ $t("commons.import") }}</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-upload"
          size="mini"
          plain
          @click="downPropertyTemplate"
          >{{ $t("commons.importTemplate") }}</el-button
        >
      </div>
      <div class="single-line">
        <span
          class="query-label"
          v-if="['PRODUCT', 'DEVICE'].includes(listQuery.type)"
        >
          选择产品：
        </span>
        <el-select
          v-if="['PRODUCT', 'DEVICE'].includes(listQuery.type)"
          :filterable="true"
          v-model="listQuery.productKey"
          placeholder="请选择产品"
          class="query-type-select single-one"
          @change="productChange"
          clearable
        >
          <el-option
            v-for="(item, index) in productList"
            :value="item.value"
            :label="item.label"
            :key="index"
          ></el-option>
        </el-select>
        <span
          class="query-label"
          v-if="['PRODUCT', 'DEVICE'].includes(listQuery.type)"
        >
          选择网关：
        </span>
        <el-select
          v-if="['PRODUCT', 'DEVICE'].includes(listQuery.type)"
          :filterable="true"
          v-model="listQuery.parentDeviceId"
          placeholder="选择网关"
          class="query-type-select single-one"
          @change="deviceIdChange"
          clearable
        >
          <el-option
            v-for="(item, index) in parentDeviceList"
            :value="item.value"
            :label="item.label"
            :key="index"
          ></el-option>
        </el-select>
        <span
          class="query-label"
          v-if="['PRODUCT', 'DEVICE'].includes(listQuery.type)"
        >
          选择设备：
        </span>
        <el-select
          v-if="['PRODUCT', 'DEVICE'].includes(listQuery.type)"
          v-model="listQuery.deviceName"
          :multiple="listQuery.type === 'PRODUCT'"
          collapse-tags
          placeholder="请选择设备"
          class="query-type-select single-one"
          clearable
          filterable
          remote
          reserve-keyword
          :remote-method="deviceRemoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in deviceList"
            :value="item.deviceName"
            :label="item.description"
            :key="item.productKey + item.deviceName"
          >
            <span style="float: left">{{ item.description }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.deviceName
            }}</span>
          </el-option>
        </el-select>
        <span class="query-label" v-if="['PRODUCT'].includes(listQuery.type)">
          模块/属性：
        </span>
        <el-cascader
          v-model="listQuery.moduleOrProperty"
          v-if="['PRODUCT'].includes(listQuery.type)"
          :filterable="true"
          :options="propertyList"
          :key="cascaderKey"
          class="query-type-select single-one"
          placeholder="请选择模块或属性"
          :props="{ checkStrictly: true }"
          clearable
          @change="moduleChange"
        ></el-cascader>
        <span
          class="query-label"
          v-if="
            ['PRODUCT'].includes(listQuery.type) &&
            listQuery.moduleOrProperty.length > 1
          "
        >
          上报值：
        </span>
        <el-select
          v-if="
            ['PRODUCT'].includes(listQuery.type) &&
            listQuery.moduleOrProperty.length > 1
          "
          placeholder="关系运算符"
          style="display: inline-block; width: 120px"
          clearable
          v-model="listQuery.operator"
          @change="oparatorChange"
        >
          <el-option
            v-for="item in operatorList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-if="
            ['PRODUCT'].includes(listQuery.type) &&
            listQuery.moduleOrProperty.length > 1
          "
          placeholder="上报值"
          :disabled="valueDisable"
          v-model="listQuery.value"
          style="display: inline-block; width: 120px; margin-left: 10px"
        >
        </el-input>
        <span class="query-label" v-if="listQuery.type === 'DEVICEGROUP'">
          选择设备组：
        </span>
        <Treeselect
          v-if="listQuery.type === 'DEVICEGROUP'"
          style="
            width: 300px;
            display: inline-block;
            height: 36px;
            margin-right: 20px;
          "
          placeholder="请选择分组"
          v-model="listQuery.groupId"
          :multiple="false"
          :default-expand-level="Infinity"
          :options="bindDeviceTreeList"
        />
      </div>
    </div>
    <finalTable
      ref="finalTableRef"
      :datas="dataset"
      @tableEventHandler="tableEventHandler"
    />
    <!--导入文件-->
    <el-dialog :visible.sync="uploadModel" width="40%">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="uploadType"
          :excelType="excelType"
          :taskType="taskType"
          :rootPath="rootPath"
          @success="uploadSuccess"
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { envInfo } from "@/constants/envInfo";
import { getProductList, findTsl } from "@/api/ruge/vlink/product/product";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  findDeviceList,
  findDevicePage,
  findDeviceGroup,
  downPropertyTemplate,
} from "@/api/ruge/vlink/device/device";
import {
  findPropertyPage,
  exportProperty,
} from "@/api/ruge/vlink/device/device";
import Upload from "@/components/Upload";
import { listToTree } from "@/utils/tree";
export default {
  name: "propertyQueryComponent",
  components: {
    finalTable,
    Treeselect,
    Upload,
  },
  data() {
    return {
      cascaderKey: 1,
      /*
      * 运算符 
        为空:isNull 
        不为空:isNotNull 
        大于:gt 
        小于:lt 
        大于等于:gtOrEquals 
        小于等于:ltOrEquals 
        等于:equals
      * */
      operatorList: [
        { label: "为空", value: "isNull" },
        { label: "不为空", value: "isNotNull" },
        { label: "等于", value: "equals" },
      ],
      bindDeviceTreeList: [],
      loadingFlag: false,
      queryTypeList: [
        {
          label: "按产品查询",
          value: "PRODUCT",
        },
        // {
        //   label: "按设备查询",
        //   value: "DEVICE",
        // },
        {
          label: "按设备组查询",
          value: "DEVICEGROUP",
        },
      ],
      parentDeviceList: [],
      productList: [],
      deviceList: [],
      resourceOptions: [],
      propertyList: [],
      deviceGroupList: [],
      uploadModel: false,
      uploadType: "ExcelImport",
      excelType: "ruge.property",
      taskType: "属性历史数据",
      rootPath: envInfo.bgApp.dorisPath,
      listQuery: {
        type: "PRODUCT",
        productKey: "",
        parentDeviceId: "",
        deviceName: [],
        moduleOrProperty: "",
        groupId: null,
        current: 1,
        rowCount: 10,
        startTime: new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
        endTime: new Date().getTime(),
        operator: "",
        value: "",
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: false,
        },
        selection: {
          need: false,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "productName",
            label: this.$t("vlink.product.productName"),
            width: "",
          },
          {
            prop: "deviceDescription",
            label: this.$t("vlink.device.deviceDescription"),
            width: "",
          },
          {
            prop: "deviceName",
            label: this.$t("vlink.device.deviceName"),
            width: "",
          },
          {
            prop: "moduleName",
            label: this.$t("vlink.tsl.moduleName"),
            width: "",
          },
          {
            prop: "propertyName",
            label: this.$t("vlink.tsl.propertyName"),
            width: "",
          },
          {
            prop: "propertyDescription",
            label: this.$t("vlink.tsl.propertDescription"),
            width: "",
          },
          {
            prop: "reportTime",
            label: this.$t("vlink.commons.reportTime"),
            width: "",
          },
          {
            prop: "value",
            label: this.$t("vlink.alarm.value"),
            width: "",
          },
          {
            prop: "unit",
            label: this.$t("vlink.tsl.unit"),
            width: "",
          },
        ],
        tableData: [],
        // 表格内容配置
        detailConfig: {
          reportTime: {
            type: "dateFormat",
          },
        },
        searchLineConfig: {
          // reportTime: {
          //   type: "dateRange",
          //   actionType: "goSearch",
          //   value: [null, null],
          // },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      dataType: "",
      deviceQueryParams: {
        parentDeviceId: null,
        current: 1,
        rowCount: 50,
        description: null,
        productKey: null,
      },
      loading: false,
      DeviceListQuery: {
        current: 1,
        rowCount: 50,
        enableStatus: "ENABLE",
        nodeType: "GATEWAY",
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
    valueDisable: {
      handler() {
        if (["isNull", "isNotNull"].includes(this.listQuery.operator)) {
          this.listQuery.value = "";
        }
      },
    },
    dataType: {
      handler(val) {
        console.log("x1", val);
        if (["int", "decimal"].includes(val)) {
          this.operatorList = this.operatorList.concat([
            { label: "大于", value: "gt" },
            { label: "小于", value: "lt" },
            { label: "大于等于", value: "gtOrEquals" },
            { label: "小于等于", value: "ltOrEquals" },
          ]);
        } else {
          this.operatorList = [
            { label: "为空", value: "isNull" },
            { label: "不为空", value: "isNotNull" },
            { label: "等于", value: "equals" },
          ];
        }
      },
      immediate: true,
    },
  },
  computed: {
    valueDisable() {
      return ["isNull", "isNotNull"].includes(this.listQuery.operator);
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    oparatorChange(value) {
      if (!value) {
        this.listQuery.value = "";
      }
    },
    moduleChange(value) {
      console.log("111", value);
      if (value.length === 0) {
        this.listQuery.operator = "";
        this.listQuery.value = "";
      }
      console.log("this.propertyList", this.propertyList);
      if (value.length > 1) {
        this.propertyList.forEach((item) => {
          if (item.value === value[0]) {
            item.children.forEach((single) => {
              if (single.value === value[1]) {
                this.dataType = single.dataType;
                console.log("this.dataType,", this.dataType);
              }
            });
          }
        });
      }
    },
    initDatas() {
      getProductList().then((productList) => {
        const nodes = productList.map((item) => ({
          value: item.productKey,
          label: item.productName,
        }));
        nodes.sort((item1, item2) => {
          return item1.label.localeCompare(item2.label, "zh-CN");
        });
        this.productList = nodes;
      });

      let param = this.DeviceListQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      findDevicePage(param).then((response) => {
        let resultList = response.rows;
        const nodes = resultList.map((item) => ({
          value: item.deviceId,
          label: item.description,
        }));
        nodes.sort((item1, item2) => {
          return item1.label.localeCompare(item2.label, "zh-CN");
        });
        this.parentDeviceList = nodes;
      }),
        findDeviceGroup().then((res) => {
          this.deviceGroupList = res.map((item) => {
            return {
              value: item.groupId,
              label: item.groupName,
            };
          });
        });
      findDeviceGroup().then((res) => {
        let data = listToTree(res, "groupId", "groupParentId");
        this.bindDeviceTreeList = this.recursivePosition(data);
      });
    },
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.groupName;
        v.id = v.groupId;
        v.show = false;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      return data;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.reportTime && datas.params.reportTime.length > 0) {
          this.listQuery.startTime = datas.params.reportTime[0]
            ? new Date(datas.params.reportTime[0]).getTime()
            : null;
          this.listQuery.endTime = datas.params.reportTime[1]
            ? new Date(datas.params.reportTime[1]).getTime()
            : null;
          delete datas.params.reportTime;
        }
        this.listQuery = {
          ...this.listQuery,
          ...datas.params,
        };
        this.queryHandler();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.queryHandler();
      }
    },
    exportHandler() {
      const params = this.buildQueryParams("export");
      exportProperty(params).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "属性历史",
          taskStatus: 0,
          rootPath: "dorisPath",
        };
        //将导出任务丢入导出任务列表中
        //异步存储
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    queryHandler(freshFlag) {
      if (!this.checkQueryState()) return;
      if (freshFlag) {
        this.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
      }
      const params = this.buildQueryParams();
      this.loadingFlag = true;
      findPropertyPage(params)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    buildQueryParams(type) {
      let params = {
        searchWay: this.listQuery.type,
        startTime: this.listQuery.startTime,
        endTime: this.listQuery.endTime,
        current: this.listQuery.current,
        rowCount: this.listQuery.rowCount,
        operator: this.listQuery.operator,
        value: this.listQuery.value,
        dataType: this.dataType,
        parentDeviceId: this.listQuery.parentDeviceId,
      };
      switch (this.listQuery.type) {
        case "PRODUCT":
          params.productKey = this.listQuery.productKey;
          console.log("this.listQuery.deviceName", this.listQuery.deviceName);
          params.deviceNames = this.listQuery.deviceName.join(",");
          const [moduleName, propertyName] =
            this.listQuery.moduleOrProperty || [];
          params.moduleName = moduleName;
          params.propertyName = propertyName;
          break;
        case "DEVICE":
          console.log("this.listQuery.deviceName", this.listQuery.deviceName);
          params.productKey = this.listQuery.productKey;
          params.deviceNames = this.listQuery.deviceName.join(",");
          break;
        case "DEVICEGROUP":
          params.groupId = this.listQuery.groupId;
          break;
      }
      for (let one in params) {
        if (!params[one]) {
          delete params[one];
        }
      }
      return params;
    },
    downPropertyTemplate() {
      downPropertyTemplate();
    },
    uploadSuccess() {
      this.uploadModel = false;
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    checkQueryState() {
      switch (this.listQuery.type) {
        case "PRODUCT":
          if (!this.listQuery.productKey) {
            this.$message.warning("请选择产品！");
            return false;
          }
          break;
        case "DEVICE":
          if (!this.listQuery.productKey) {
            this.$message.warning("请选择产品！");
            return false;
          }
          if (!this.listQuery.deviceName.length) {
            this.$message.warning("请选择设备！");
            return false;
          }
          break;
        case "DEVICEGROUP":
          if (!this.listQuery.groupId) {
            this.$message.warning("请选择设备组！");
            return false;
          }
          break;
      }
      return true;
    },
    deviceRemoteMethod(query, product) {
      if (product || this.listQuery.productKey) {
        this.loading = true;
        let params = this.deviceQueryParams;
        params.parentDeviceId = this.listQuery.parentDeviceId;
        params.searchPhrase = query;
        params.productKey = product || this.listQuery.productKey || "";
        findDevicePage(params)
          .then((res) => {
            // const currentDevices = res.rows.map((item) => {
            //   return {
            //     value: item.deviceName,
            //     label: item.description
            //       ? `${item.description} (${item.deviceName})`
            //       : `${item.deviceName} (${item.deviceName})`,
            //   };
            // });
            this.deviceList = res.rows;
            if (!this.resourceOptions.length) {
              this.resourceOptions = res.rows;
            }
            console.log("deviceList", this.deviceList);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deviceList = this.resourceOptions;
      }
    },
    deviceIdChange() {
      this.listQuery.deviceName = [];
      this.deviceRemoteMethod("");
    },
    productChange(productKey) {
      ++this.cascaderKey;
      this.deviceList = [];
      this.listQuery.deviceName = [];
      this.propertyList = [];
      this.listQuery.moduleOrProperty = "";
      if (!productKey) return;
      // findDeviceList({ productKey }).then((deviceList) => {
      //   const nodes = deviceList.map((item) => ({
      //     value: item.deviceName,
      //     label: item.deviceName,
      //   }));
      //   this.deviceList = nodes;
      // });
      this.deviceRemoteMethod("");
      findTsl({ productKey }).then((res) => {
        // 根据模块名称匹配该模块下事件的参数列表
        res.modules.forEach((item) => {
          let currentObj = {
            value: item.moduleName,
            label: item.moduleName,
            children: [],
          };
          item.properties &&
            item.properties.length &&
            item.properties.forEach((property) => {
              currentObj.children.push({
                value: property.propertyName,
                label: property.description,
                dataType: property.dataType,
              });
            });
          this.propertyList.push(currentObj);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.property-query-component {
  .filter-line {
    .query-type-select {
      width: 180px;
    }
    .single-one {
      margin-right: 10px;
    }
    .single-line {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }
    .query-label {
      font-size: 14px;
      display: inline-block;
      width: 85px;
      text-align: right;
      margin-right: 8px;
    }
    .date-range-span {
      margin: 0 10px;
    }
  }
}
</style>